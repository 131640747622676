import React, { useContext } from "react";
import Link from "next/link";
import ReactMarkdown from "react-markdown";

import {
  fetchPackagesWithContent,
  fetchProductionSeasonsWithContent,
} from "../../lib/tessitura";

import MofoContext from "../../context/MofoContext";
import { FilteredListing } from "../../components/FilteredListing";
import {
  H2,
  H3,
  HRLight,
  mungeTitleText,
  NoTicketsMessage,
} from "../../components/Styling";
import {
  FilterableItem,
  FilteredItem,
  PackageDetailWithContent,
  ProductionSeasonWithContent,
} from "../../lib/types";
import { formatPerformanceDate } from "../../lib/dates";
import FavouriteButton from "../../components/FavouriteButton";
import { GetSponsors } from "../api/sponsors";
import { filterDateTimesByTessituraPublishWebDateConstraints } from "../../lib/filterers";
import Meta from "../../components/Meta";

type FilterableProductionSeason = ProductionSeasonWithContent & FilterableItem;
type FilterablePackage = PackageDetailWithContent & FilterableItem;
type FilteredProductionSeason = ProductionSeasonWithContent & FilteredItem;
type FilteredPackage = PackageDetailWithContent & FilteredItem;

export default function MonaFoma2022({
  itemsWithContent,
}: {
  itemsWithContent: (ProductionSeasonWithContent | PackageDetailWithContent)[];
}) {
  return (
    <>
      <Meta title="Tickets" description="Tickets on sale now."></Meta>

      <TicketedItemsListing itemsWithContent={itemsWithContent} />
    </>
  );
}

function TicketedItemsListing({
  itemsWithContent,
}: {
  itemsWithContent: (ProductionSeasonWithContent | PackageDetailWithContent)[];
}): JSX.Element {
  const { lite } = useContext(MofoContext);

  // This component displays the filtered items from `<FilteredListing />`
  const FilteredItemsComponent = ({
    filteredItems,
    isDateMode,
  }: {
    filteredItems: (FilteredProductionSeason | FilteredPackage)[];
    isDateMode: boolean;
  }) => {
    return (
      <div className="sm:max-w-xl flex flex-col gap-8 p-6 sm:px-0">
        {filteredItems.map((item) => {
          const title = item.content.titleOverride || item.content.title;
          const validDateTimes =
            filterDateTimesByTessituraPublishWebDateConstraints(
              item.content.dateTimes,
              true // isTicketsPage
            );
          const datesSummary =
            item.content.datetimesSummaryOverride ||
            item.content.dateTimesShortSummaryOverride ||
            validDateTimes
              ?.map((dt) => formatPerformanceDate(dt.startDate))
              .join(", ");
          const facilityTitle =
            item.content.facilities?.[0]?.addressExtra ||
            item.content.facilities?.[0]?.titleOverride ||
            item.content.facilities?.[0]?.title;

          const isSoldOut = item.content?.soldOut;
          const isPastEvent = !(validDateTimes?.length > 0);

          const detailsComponent = (
            <>
              <H2 title={mungeTitleText(title)} align="left" />
              <H3 title={item.content.subtitleExtra} align="left" />

              <div className="flex flex-row items-end">
                <div className="flex-grow flex flex-col">
                  <div className="markdown font-bold">
                    <ReactMarkdown>{datesSummary}</ReactMarkdown>
                  </div>

                  <div className="font-bold">{facilityTitle}</div>

                  <div className="font-bold">
                    {item.content.costSummaryExtra}
                  </div>
                </div>

                <FavouriteButton
                  key={`${item.Id}-content`}
                  event={item.content}
                  className="flex-shrink-0 m-1"
                />
              </div>
            </>
          );

          return (
            <div key={item.Id} className="flex flex-col gap-6 w-full sm:w-96">
              <HRLight />

              {isSoldOut ? (
                <div className="opacity-40">
                  {detailsComponent}
                  <NoTicketsMessage
                    itemWithContent={item}
                    className="mt-3 bg-none border-cyan"
                  />
                </div>
              ) : isPastEvent ? (
                <Link passHref={true} href={"/" + item.content.slug}>
                  <a className="no-underline opacity-60">
                    {detailsComponent}
                    <NoTicketsMessage
                      itemWithContent={item}
                      message="This event has passed"
                      className="mt-3 bg-none border-cyan"
                    />
                  </a>
                </Link>
              ) : (
                <Link passHref={true} href={"/" + item.content.slug}>
                  <a className="no-underline">{detailsComponent}</a>
                </Link>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  const filterableItems: (FilterableProductionSeason | FilterablePackage)[] =
    itemsWithContent.map((item) => {
      return {
        ...item,
        slug: item.content.slug || String(item.Id),
        title: item.content.titleOverride || item.content.title,
        dateTimes: filterDateTimesByTessituraPublishWebDateConstraints(
          item.content.dateTimes
        ),
        tagsExtra: item.content.tagsExtra,
      };
    });

  return (
    <main>
      <FilteredListing<FilterableProductionSeason | FilterablePackage>
        items={filterableItems}
        FilteredItemsComponent={FilteredItemsComponent}
        heading="Tickets"
        bgColour={"bg-white"}
      />
    </main>
  );
}

export async function getStaticProps() {
  const [productionSeasonsWithContent, _packagesDetailsWithContent, sponsors] =
    await Promise.all([
      fetchProductionSeasonsWithContent(),
      fetchPackagesWithContent(),
      GetSponsors(),
    ]);

  // HACK to hide Midnight Oil and transport-only packages from the tickets listing
  const packagesDetailsWithContent = _packagesDetailsWithContent.filter(
    (packageWithContent) =>
      [
        182, // Midnight Oil + Transport, Friday 28 January
        183, // Midnight Oil + Transport, 31 January
        184, // Mofo Transport
      ].indexOf(Number(packageWithContent.Id)) < 0
  );

  const itemsWithContent = [
    ...productionSeasonsWithContent,
    ...packagesDetailsWithContent,
  ];

  return {
    props: {
      itemsWithContent,
      showTicketsComingSoonSplashPage: !(itemsWithContent?.length > 0),
      isTicketsPage: true,
      sponsors,
    },
    revalidate: 60,
  };
}
