// TODO Use a different lib than 'date-fns', maybe 'luxon'
import { format as dateFnsFormat, addHours } from "date-fns";
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz";

export type DateParam = string | number | Date;

export function makeHobartDate(date: DateParam): Date {
  const utcDate = zonedTimeToUtc(date, "Australia/Hobart");
  return utcToZonedTime(utcDate, "Australia/Hobart");
}

export function formatDate({
  date,
  format = "y-MM-dd HH:MM",
  raise = true,
}: {
  date: DateParam;
  format?: string;
  raise?: boolean;
}) {
  if (!date && !raise) {
    return "";
  }

  return dateFnsFormat(makeHobartDate(date), format);
}

export function formatDateAsYMD(date: DateParam): string {
  return formatDate({ date, format: "y-MM-dd" });
}

export function formatDateAsDay(date: DateParam): string {
  return formatDate({ date, format: "eeee" });
}

export function formatDateAsShortTime(date: DateParam): string {
  return formatDate({ date, format: "ha" });
}

export function formatDateAsLongTime(date: DateParam): string {
  return formatDate({ date, format: "HH:mm" });
}

export function formatDateAsMonaTime(date: DateParam): string {
  date = date instanceof Date ? date : new Date(date);

  if (date.getMinutes() === 0) {
    return formatDate({ date, format: "haaa" });
  } else {
    return formatDate({ date, format: "h.mmaaa" });
  }
}

export function formatDateAsDayNameDateDigitsMonthName(
  date: DateParam
): string {
  return formatDate({ date, format: "eeee d LLLL" });
}

export function formatPerformanceDate(date: DateParam): string {
  return `${formatDateAsDayNameDateDigitsMonthName(
    date
  )}, ${formatDateAsMonaTime(date)}`;
}

// Reformat Tessitura zone style times in a string e.g: "8:00PM" => "8pm"
export function reformatTimeString(
  textWithTime: string,
  durationHours: number = 0
) {
  try {
    const found = textWithTime?.match(/(.*?)(\d+):(\d\d) *(AM|PM)/i);

    if (!found) {
      return textWithTime;
    }

    let [_, prefix, hours, minutes, amPm] = found;

    if (Number(hours) < 12 && amPm.toLowerCase() === "pm") {
      hours = String(Number(hours) + 12);
    }
    // Handle midnight: "12:00AM" => "00:00"
    else if (hours === "12" && amPm.toLowerCase() === "am") {
      hours = "00";
    }

    const dateStr = new Date(2022, 0, 29, Number(hours), Number(minutes));
    const startDate = new Date(dateStr);

    if (durationHours > 0) {
      const endDate = addHours(startDate, durationHours);
      return (
        prefix +
        formatDateAsMonaTime(startDate) +
        " to " +
        formatDateAsMonaTime(endDate)
      );
    } else {
      return prefix + formatDateAsMonaTime(startDate);
    }
  } catch (error) {
    console.error(error);
    return textWithTime;
  }
}
